import React from 'react';
import './RuleList.css';

const RuleList = () => (
  <ul className="rule-list">
    <li>You can only play once</li>
  </ul>
);

export default RuleList;
