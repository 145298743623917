import React from 'react';
import Counter from '../Counter/Counter';
import HeaderNavigation from '../Navigation/HeaderNavigation';
import FooterNavigation from '../Navigation/FooterNavigation';
import logo from '../../logo.png';
import './Layout.css';

const Layout = ({ children }) => (
  <div className="layout">
    <Counter />
    <header>
      <HeaderNavigation />
    </header>
    <section className="content-section">{children}</section>
    <footer>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <FooterNavigation />
    </footer>
  </div>
);

export default Layout;
