import React from 'react';
import Countdown from 'react-countdown';
import './Counter.css';

const Counter = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return 'Competition ended';
    } else {
      // Render a countdown
      return `Competition ends in: ${days}:${hours}:${minutes}:${seconds}`;
    }
  };
  return (
    <div className="counter">
      <Countdown date={new Date(1580911200000)} renderer={renderer} />
    </div>
  );
};

export default Counter;
