import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrophy,
  faClipboardList,
  faAward
} from '@fortawesome/free-solid-svg-icons';

const HeaderNavigation = () => (
  <nav>
    <NavLink exact to="/" activeClassName="active">
      <FontAwesomeIcon icon={faTrophy} />
      &nbsp;Score Table
    </NavLink>
    <NavLink exact to="/rules" activeClassName="active">
      <FontAwesomeIcon icon={faClipboardList} />
      &nbsp;Rules
    </NavLink>
    <NavLink exact to="/prizes" activeClassName="active">
      <FontAwesomeIcon icon={faAward} />
      &nbsp;Prizes
    </NavLink>
  </nav>
);

export default HeaderNavigation;
