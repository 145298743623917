import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Navigation.css';

const Navigation = () => (
  <nav>
    <div>
      <a
        href="https://www.fiwe.com/se/om-fiwe/"
        target="_blank"
        rel="noopener noreferrer"
      >
        About Fiwe
      </a>
      <a
        href="https://www.fiwe.com/se/karriar/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Career
      </a>
      <a
        href="https://www.fiwe.com/se/kontakt/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact
      </a>
    </div>
    <div className="social">
      <a
        href="https://www.instagram.com/fiwe_commerce/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        href="https://www.linkedin.com/company/fiwe-systems-&-consulting-ab"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </div>
  </nav>
);

export default Navigation;
