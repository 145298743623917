import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Score from './components/Pages/Score';
import Rules from './components/Pages/Rules';
import Prizes from './components/Pages/Prizes';
import PageNotFound from './components/Pages/PageNotFound';
import './App.css';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Score} />
          <Route exact path="/rules" component={Rules} />
          <Route exact path="/prizes" component={Prizes} />
          <Route component={PageNotFound} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
