import React from 'react';
import ScoreTable from '../ScoreTable/ScoreTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const Score = () => (
  <>
    <h1>
      <FontAwesomeIcon icon={faTrophy} />
      &nbsp;Score Table
    </h1>
    <PerfectScrollbar
      style={{ width: '100%', maxWidth: '600px', maxHeight: '380px' }}
    >
      <ScoreTable />
    </PerfectScrollbar>
  </>
);

export default Score;
