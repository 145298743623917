import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import './ScoreTable.css';

const ScoreTable = () => {
  const [scores, setScores] = useState([]);
  useEffect(() => {
    fetch('/score')
      .then(response => response.json())
      .then(jsonResponse => setScores(jsonResponse));
    const eventSource = new EventSource('/score');
    eventSource.onmessage = ({ data }) => setScores(JSON.parse(data));
  }, []);
  const scoreRows = scores.map((score, index) => {
    const awardBadge =
      index < 3 ? (
        <>
          <FontAwesomeIcon icon={faAward} />
          &nbsp;
        </>
      ) : (
        ''
      );
    return (
      <tr key={index} className={`rank-${index + 1}`}>
        <td>
          {awardBadge}
          {index + 1}
        </td>
        <td>{score.nickName}</td>
        <td>{score.score}</td>
      </tr>
    );
  });
  return (
    <table>
      <thead>
        <tr>
          <th>Rank</th>
          <th>Nick</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>{scoreRows}</tbody>
    </table>
  );
};

export default ScoreTable;
