import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import './PrizeList.css';

const PrizeList = () => (
  <ul className="prize-list">
    <li>
      <span className="rank-1">
        <FontAwesomeIcon icon={faAward} />
      </span>
      &nbsp;iPad
    </li>
    <li>
      <span className="rank-2">
        <FontAwesomeIcon icon={faAward} />
      </span>
      &nbsp;Raspberry Pi Starter Kit
    </li>
    <li>
      <span className="rank-3">
        <FontAwesomeIcon icon={faAward} />
      </span>
      &nbsp;Raspberry Pi
    </li>
  </ul>
);

export default PrizeList;
