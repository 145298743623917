import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import PrizeList from '../PrizeList/PrizeList';

const Prizes = () => (
  <>
    <h1>
      <FontAwesomeIcon icon={faAward} />
      &nbsp;Prizes
    </h1>
    <PrizeList />
  </>
);

export default Prizes;
