import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import RuleList from '../RuleList/RuleList';

const Rules = () => (
  <>
    <h1>
      <FontAwesomeIcon icon={faClipboardList} />
      &nbsp;Rules
    </h1>
    <RuleList />
  </>
);

export default Rules;
